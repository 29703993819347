<template>
  <div v-if="providers.length > 0" id="providers-swiper">
    <section class="game-studio">
      <div class="c-swiper-header">
        <div class="c-swiper-header__box">
          <router-link
            :to="{
              name: 'GamesProvider',
              params: { apiProviderName: encodeData(apiProviderName) },
            }"
            class="games-sections__title is-clickable"
          >
            <span class="title" style="margin-left:10px; font-size: 26px;">👾 Game Studios </span>
          </router-link>
          <button
            @click="scrollLeft"
            :disabled="isAtStart"
            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
          </button>
          <button
            @click="scrollRight"
            :disabled="isAtEnd"
            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
          >
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </button>
        </div>
        <div class="c-swiper-header__box" style="width: 100px">
          <router-link
            :to="{
              name: 'GamesProvider',
              params: { apiProviderName: encodeData(apiProviderName) },
            }"
            class="c-button c-view-all c-view-all color-light-purple size-m"
          >
            <span style="font-size: 14px; padding: 2px 0px">{{
              t("View all")
            }}</span>
          </router-link>
        </div>
      </div>
      <div style="position: relative">
        <div
          @mouseenter="showNavigation = true"
          @mouseleave="showNavigation = false"
          class="provider-container"
          ref="swiperContainer"
        >
          <div
            class="provider-box"
            v-for="(provider, index) in providers"
            :key="provider._id.$oid"
          >
            <router-link
              :to="`/game-by-provider/${encodeData(parseData(provider.provider_name))}/${
               encodeData(provider.provider_id)
              }/${encodeData(apiProviderName)}`"
              :style="
                index !== 0
                  ? 'margin-left: 15px; text-decoration: none; display: flex; gap: 30px;'
                  : 'text-decoration: none; display: flex; gap: 30px;'
              "
            >
              <div v-if="provider.image_url" class="c-game-box provider-text">
                <img
                  style=""
                  :src="provider.image_url"
                  alt="Game Image"
                  class="c-game-box__image game-casino"
                />
              </div>

              <div v-else class="provider-text" style="text-decoration: none">
                {{ parseData(provider.provider_name) }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="leftAndRight">
          <button
            style="margin-top: 5px; background-color: #6f6e74; opacity: 0.9"
            @click="scrollLeft"
            :disabled="isAtStart"
            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
          </button>
          <button
            style="margin-top: 5px; background-color: #6f6e74; opacity: 0.9"
            @click="scrollRight"
            :disabled="isAtEnd"
            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
          >
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { encodeData } from "@/utils/utils";

export default {
  name: "ProviderMapping",
  components: {
    FontAwesomeIcon,
  },
  props: {
    apiProviderName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      providers: [],
      isAtStart: true,
      isAtEnd: false,
      showNavigation: false,
    };
  },
  mounted() {
    this.fetchProviders();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    convertToReadableFormat(inputString) {
      return inputString.replace(/_/g, " ");
    },
    encodeData,
    async fetchProviders() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_KEY}/api/secondary-provider/get-provider?limit=50&status=true&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
        );
        if (response.data.success) {
          this.providers = response.data.data;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching providers:", error);
      }
    },

    parseData(string) {
      if (!string) {
        return "Invalid input"; // Handle null or empty input
      }

      try {
        const parsedData = JSON.parse(string);
        return this.convertToReadableFormat(parsedData.en);
      } catch (error) {
        return this.convertToReadableFormat(string);
      }
    },
    scrollLeft() {
      const container = this.$refs.swiperContainer;
      container.scrollLeft -= container.clientWidth / 2;
      this.checkScrollPosition();
    },
    scrollRight() {
      const container = this.$refs.swiperContainer;
      container.scrollLeft += container.clientWidth / 2;
      this.checkScrollPosition();
    },
    checkScrollPosition() {
      const container = this.$refs.swiperContainer;
      this.isAtStart = container.scrollLeft === 0;
      this.isAtEnd =
        container.scrollLeft + container.clientWidth >= container.scrollWidth;
    },
  },
  watch: {
    providers() {
      this.$nextTick(() => {
        this.checkScrollPosition();
      });
    },
  },
};
</script>

<style scoped>
.game-studio {
  /* Add styles here */
  margin-left: 0px;
}

.c-swiper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.provider-title {
  /* style="color:white;font-size: 20px" */
  color: white;
  font-size: 20px;
}
.c-swiper-header__box {
  display: flex;
  align-items: center;
}

.games-sections__title.is-clickable {
  font-size: 1.5rem;
  cursor: pointer;
  color: #6f6e74;
  margin-right: 15px;
}
.title {
  font-size: 20px;
  color: white;
}
@media (max-width: 824px) {
  .title {
    font-size: 14px;
  }

  .provider-container {
    display: flex;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 1px;
  }
}
.c-button {
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.c-swiper-navigation__arrow.has-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-light-purple {
  /* background-color: #6f6e74; */
}

.size-m {
  font-size: 1rem;
}

.provider-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow-x: auto;
  gap: 0px;
  margin-left: 0px;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.provider-box {
  /* margin-right: 16px; */
  border-radius: 8px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  /* border:2px solid red; */
}

.provider-text {
  width: 250px;
  padding: 20px;
  background-color: #272533;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  height: 120px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
}

.c-view-all {
  padding: 8px 16px;
  /* background-color: #6f6e74; */
  color: white;
  text-decoration: none;
  border-radius: 6px;
}

.c-view-all:hover {
  background-color: #56546e;
}
.leftAndRight {
  position: absolute;
  top: 25px;
  right: -10px;
  z-index: 100;
}
@media (max-width: 1024px) {
  .provider-text {
    width: 160px;
    padding: 20px;
    background-color: #272533;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leftAndRight {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
  }
  .provider-container {
    margin-left: -12px;
    /* Hide scrollbar in Firefox */
  }

  .provider-box {
    /* margin-right: 16px; */
    border-radius: 8px;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    /* border:2px solid red; */
  }
}
</style>
