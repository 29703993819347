<template>
  <div>
    <div v-if="games.length > 0" >
      <section class="games-sections-wrapper">
        <div
          data-cy="cy-games-sections__heading-skeleton"
          class="games-sections__heading"
        >
          <div
            data-cy="cy-games-sections__heading-box-skeleton"
            class="games-sections__heading-box"
          >
            <div
              data-cy="cy-games-sections__title-skeleton"
              class="games-sections__title"
            >
              <span class="title"><img :src="icon" style="width:30px;height:30px" /> {{ t(gameCategory) }} </span>
            </div>
            <button
              @click="scrollLeft"
              :disabled="isAtStart"
              class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
            >
              <font-awesome-icon :icon="['fas', 'arrow-left']" />
            </button>
            <button
              @click="scrollRight"
              :disabled="isAtEnd"
              class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
            >
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </button>
          </div>
          <div data-cy="cy-swiper-header__box" class="c-swiper-header__box">
            <router-link
              :to="`/ex-game-category/${encodeData(gameCategory)}/name`"
              class="c-button c-view-all color-light-purple size-m"
            >
              <span>{{ t("View all") }}</span>
            </router-link>
            <div class="c-swiper-navigation"></div>
          </div>
        </div>
        <div style="position: relative;">
          <div
            @mouseenter="showNavigation = true"
            @mouseleave="showNavigation = false"
            class="provider-container"
           
            ref="swiperContainer"
          >
            <div
              v-for="game in games"
              :key="game._id"
              class="game-box" 
              style="display: flex;"
            >
              <router-link
                :to="`/game/exclusive-casino-game/${encodeData(parseData(game.provider_id))}/${
                  encodeData(game.game_id)
                }/${encodeData(game.api_provider_name)}`"
                class="c-game-container game-route"
                style="text-decoration: none"
              >
                <div v-if="game.image_url" class="c-game-box">
                  <img
                    :src="game.image_url"
                    alt="Game Image"
                    class="c-game-box__image image-casino"
                  />
                </div>
                <div v-else>
                  <div
                    class="image-casino"
                    style="
                      background-color: #272533;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 8px;
                      color: white;
                    "
                  >
                    <div style="text-decoration: none">
                      {{ game.game_name }}
                    </div>
                    <div style="text-decoration: none">
                      {{ parseData(game.provider) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div
              :class="{
                'show-navigation': showNavigation,
                'hide-navigation': !showNavigation,
              }"
              class="navigation-buttons"
              style="position: absolute; top: 100px; right: 0px; z-index: 20"
            >
              <button
                style="margin-top: 5px; background-color: #6f6e74; opacity: 0.9"
                @click="scrollLeft(0)"
                :disabled="isAtStart"
                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
              >
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
              </button>
              <button
                style="margin-top: 5px; background-color: #6f6e74; opacity: 0.9"
                @click="scrollRight(0)"
                :disabled="isAtEnd"
                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
              >
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { encodeData } from "@/utils/utils";

export default {
  name: "GameCategory",
  components: {
    FontAwesomeIcon,
    
  },
  props: {
    gameCategory: {
      type: String,
      required: true,
    },
    icon:{
      type:String,
    }
  },
  data() {
    return {
      games: [],
      isAtStart: true,
      isAtEnd: false,
      showNavigation: false,
    };
  },
  mounted() {
    this.fetchGames();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    encodeData,
    convertToReadableFormat(inputString) {
      return inputString.replace(/_/g, " ");
    },
    async fetchGames() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_KEY}/api/secondary-game/get-game-by-game-category?game_category=${this.gameCategory}&status=true&limit=60&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
        );
        if (response.data.success) {
          this.games = response.data.data;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    },
    parseData(string) {
      if (!string) {
        return "Invalid input"; // Handle null or empty input
      }

      try {
        const parsedData = JSON.parse(string);
        return this.convertToReadableFormat(parsedData.en);
      } catch (error) {
        return this.convertToReadableFormat(string);
      }
    },
    scrollLeft() {
      const container = this.$refs.swiperContainer;
      container.scrollLeft -= container.clientWidth / 2;
      this.checkScrollPosition();
    },
    scrollRight() {
      const container = this.$refs.swiperContainer;
      container.scrollLeft += container.clientWidth / 2;
      this.checkScrollPosition();
    },
    checkScrollPosition() {
      const container = this.$refs.swiperContainer;
      this.isAtStart = container.scrollLeft === 0;
      this.isAtEnd =
        container.scrollLeft + container.clientWidth >= container.scrollWidth;
    },
  },
  watch: {
    games() {
      this.$nextTick(() => {
        this.checkScrollPosition();
      });
    },
  },
};
</script>

<style scoped>
.game-studio {
  justify-self: center;
  margin-left: 0%;
  margin-right: 5%;
}

.c-swiper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.c-swiper-header__box {
  display: flex;
  align-items: center;
}

.games-sections__title.is-clickable {
  font-size: 1.5rem;
  cursor: pointer;
  color: #6f6e74;
  margin-right: 16px;
}

.c-button {
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.c-swiper-navigation__arrow.has-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-light-purple {
  /* background-color: #6f6e74; */
}

.size-m {
  font-size: 1rem;
}
.image-casino:hover {
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
    padding 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  transform: scale(1.03);
}

.provider-container {
  display: flex;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
padding-top: 15px; 
padding-bottom: 15px;
gap:18px;
/* margin-left:10px; */
}

.without-image {
  height: 100px;
  gap: 2px;
  border-radius: 8px;
  background-color: #272533;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.without-image:hover {
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
    padding 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  transform: scale(1.1);
}

.provider-container::-webkit-scrollbar {
  display: none;
}

.provider-box {
  /* padding: 8px; */
  border-radius: 8px;
  height: 220px;
}

.provider-text {
  text-align: center;
  font-size: 1rem;
  color: white;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.provider-text.with-image {
  background: none;
}
.show-navigation {
  opacity: 0.8;
  visibility: visible;
}

.hide-navigation {
  opacity: 0;
  visibility: hidden;
}

.game-image {
  width: 200px;
  height: 220px;
  object-fit: cover;

  border-radius: 8px;
  margin-bottom: 8px;
}

.c-view-all {
  padding: 8px 16px;
  /* background-color: #6f6e74; */
  color: white;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
}

.c-view-all:hover {
  background-color: #523da6;
}
.title {
  font-size: 24px;
}
@media (max-width: 824px) {
  .title {
    font-size: 15px;
  }

  .provider-container {
    display: flex;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 1px;
    gap:10px;
margin-left:-10px;
  }
}
</style>
